export default [
  {
    header: 'APP MENU',
  },
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  {
    title: 'Projects',
    route: 'project-list',
    icon: 'BoxIcon',
    Permission: '',
  },

  {
    title: 'Invoices',
    route: 'invoice-list',
    icon: 'ClipboardIcon',
    Permission: '',
  },

  {
    title: 'Quotes',
    route: 'quote-list',
    icon: 'FileIcon',
    Permission: '',
  },

  // {
  //   title: 'Marketing Dashboard',
  //   href: 'https://www.rheliteservices.com/service/marketing-and-branding.html',
  //   icon: 'CornerRightUpIcon',
  //   Permission: '',
  // },

  // {
  //   title: 'Back to main site',
  //   href: 'https://www.rheliteservices.com/',
  //   icon: 'CornerRightUpIcon',
  //   Permission: '',
  // },

  {
    title: 'Users',
    icon: 'UsersIcon',
    Permission: 'user_list',
    children: [
      {
        title: 'Add',
        route: 'user-add',
        icon: 'UserPlusIcon',
      },
      {
        title: 'List',
        route: 'user-list',
        icon: 'ListIcon',
      },
      {
        title: 'Edit',
        route: 'user-edit',
        icon: 'EditIcon',
        disabled: true,
      },
    ],
  },
];
